import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import BootstrapTable from "react-bootstrap-table-next";
import { Button, Card, CardBody } from "reactstrap";

import { dateFormat } from "utils/format";
import { CheckCircle } from "react-feather";
import { Cut } from "components/Cut";
import { getOrder, getUpd, updateDelivery, updateOrder } from "services/api";
import { useUser } from "features/Users/UserContext";
import { LabelModal } from "features/Orders/LabelModal";
import { EditableInput } from "components/EditableInput";
import { createPortal } from "react-dom/cjs/react-dom.production.min";
import { PrintLabel } from "features/Orders/PrintLabel";
import { PrintUtd } from "features/Orders/PrintUtd";
import { PrintInvoice } from "features/Orders/PrintInvoice";
import { ChangeRoute } from "../ChangeRoute";
import { PrintAssemblyList } from "features/Orders/PrintAssemblyList";

import { SelectDriver } from "../SelectDriver";

import "./deliveryListTable.scss";

export const DeliveryListTable = ({
  setLoading,
  deliveryList,
  getAndSetDeliveryList,
  loading
}) => {
  const { user, admins } = useUser();
  const [expanded, setExpanded] = useState([]);
  const [openModalLabel, setOpenModalLabel] = useState(false);
  const [orderId, setOrderid] = useState(null);
  const [quantityLabels, setQuantityLabels] = useState(0);
  const [order, setOrder] = useState(false);
  const [showPrintInvoice, setShowPrintInvoice] = useState(false);
  const [showPrintUtd, setShowPrintUtd] = useState(false);
  const [showPrintLabels, setShowPrintLabels] = useState(false);
  const [showPrintAssembliList, setShowPrintAssembliList] = useState(false);
  const [utd, setUtd] = useState(null);


  useEffect(() => {
    setExpanded(deliveryList.map((item) => item.id));
  }, [deliveryList]);

  const driverHandler = (id, driver) => {
    setLoading(true);
    updateDelivery({
      id,
      driver,
    })
      .then((res) => {
        getAndSetDeliveryList();
      })
      .catch((e) => {
        toast.error(e.response?.data?.message);
        setLoading(false);
      });
  };

  const deliveryHandler = (id) => {
    setLoading(true);
    updateOrder({
      id,
      delivered: true,
    })
      .then(() => {
        getAndSetDeliveryList();
        toast.success("Статус доставки успешно сохранен");
      })
      .catch((e) => {
        toast.error(e.response?.data?.message);
        setLoading(false);
      });
  };

  const quantityLabelsHandler = (id, val) => {
    setLoading(true);
    updateOrder({
      id,
      quantityLabels: val
    })
      .then(() => {
        getAndSetDeliveryList();
        toast.success("Кол-во мест успешно сохранено");
      })
      .catch((e) => {
        toast.error(e.response?.data?.message);
        setLoading(false);
      });
  };

  const quantityPalletsHandler = (item, val) => {
    if (!val || !item.settings?.quantityLabels) return;
    if (val > item.settings?.quantityLabels) {
      toast.error("Кол-во паллет не может быть больше кол-ва мест");
      return;
    }
    setLoading(true);
    updateOrder({
      id: item.id,
      quantityPallets: val
    })
      .then(() => {
        getAndSetDeliveryList();
        toast.success("Кол-во паллет успешно сохранено");
      })
      .catch((e) => {
        toast.error(e.response?.data?.message);
        setLoading(false);
      });
  };

  const tableColumns = [
    {
      dataField: "deliveryType",
      text: "Тип дост.",
      formatter: (cell, row) => <Cut>{cell.name}</Cut>,
      headerStyle: () => {
        return { width: "5%" };
      },
    },
    {
      dataField: "transportCompany",
      text: "ТК",
      formatter: (cell, row) => <Cut>{cell}</Cut>,
      headerStyle: () => {
        return { width: "6%" };
      },
    },
    {
      dataField: "address",
      text: "Адрес",
      formatter: (cell, row) => <Cut>{cell}</Cut>,
      headerStyle: () => {
        return { width: "14%" };
      },
    },
    {
      dataField: "date",
      text: "Дата дост.",
      formatter: (cell, row) => dateFormat(cell),
      headerStyle: () => {
        return { width: "6%" };
      },
    },
    {
      dataField: "customer",
      text: "Клиент",
      formatter: (cell) => <Cut>{cell}</Cut>,
      headerStyle: () => {
        return { width: "10%" };
      },
    },
    {
      dataField: "driver",
      text: "Водитель",
      headerStyle: () => {
        return { width: "7%" };
      },
      formatter: (cell, row) => (
        <SelectDriver row={row} admins={admins} driverHandler={driverHandler} />
      ),
      events: {
        onClick: (e) => {
          e.stopPropagation();
        },
      },
    },
    {
      dataField: "number",
      text: "№",
      formatter: (cell, row) => (
        <a href={`/sales/orders/${row.orderId}`} rel="no-referer">
          {cell}
        </a>
      ),
      headerStyle: () => {
        return { width: "4%" };
      },
    },
    {
      dataField: "status",
      text: "Статус",
      headerStyle: () => {
        return { width: "4%" };
      },
    },
    {
      dataField: "delivery",
      text: "Отгрузка",
      headerStyle: () => {
        return { width: "6%" };
      },
    },
    {
      dataField: "quantityLabels",
      text: "Мест",
      headerStyle: () => {
        return { width: "3%" };
      },
    },
    {
      dataField: "quantityPallets",
      text: "Паллет",
      headerStyle: () => {
        return { width: "3%" };
      },
    },
    {
      dataField: "payer",
      text: "Плательщик",
      headerStyle: () => {
        return { width: "8%" };
      },
    },
    {
      dataField: "route",
      text: "Маршрут",
      formatter: (cell, row) => {
        if (order.carriedOut) {
          return cell?.name;
        }
        return <ChangeRoute
          row={row}
          getAndSetDeliveryList={getAndSetDeliveryList}
          setLoading={setLoading}
        />;
      },
      classes: "route",
      headerStyle: () => {
        return { width: "7%" };
      },
    },
    {
      dataField: "admin",
      text: "Менеджер",
      headerStyle: () => {
        return { width: "8%" };
      },
    },
    {
      dataField: "comment",
      text: "Комментарий",
      headerStyle: () => {
        return { width: "16%" };
      },
    },
  ];

  const expandHandler = () => {
    if (expanded.length === 0) {
      setExpanded(deliveryList.map((item) => item.id));
    } else {
      setExpanded([]);
    }
  };

  useEffect(() => {

    if (showPrintInvoice) {
      window.print();
      window.onafterprint = function () {
        setShowPrintInvoice(false);
        setShowPrintUtd(true);
      };
    }

    // if (utd && showPrintLabels) {
    //   window.print();
    //   window.onafterprint = function () {
    //     setShowPrintLabels(false);
    //   };
    // }

    if (utd && showPrintUtd) {
      window.print();
      window.onafterprint = function () {
        setShowPrintUtd(false);
      };
    }

  }, [showPrintUtd, showPrintInvoice, showPrintLabels]);

  const getAndSetOrder = (order) => {
    setLoading(true);
    setOrder(null);
    getOrder(order.id)
      .then(setOrder)
      .catch((e) => toast.error(e?.response?.data?.message))
      .finally(() => { });

    getUpd({ id: order.id, json: true })
      .then(setUtd)
      .catch((e) => toast.error(e?.response?.data?.message))
      .finally(() => {
      });
  }

  const printHandler = (order) => {

    setOrderid(order.id);
    setOpenModalLabel(true);

    setOrder(null);
    setUtd(null);

    getAndSetOrder(order);

    setOpenModalLabel(true);
  }

  const expandRow = {
    renderer: (row) => {
      return (
        <div className="react-bootstrap-table">
          <table className="table table-hover">
            <tbody>
              {row.orders?.map((item) => (
                <tr key={item.id}>
                  <td style={{ width: "48%" }}>
                    <div className="actions">
                      {item.statusAlias === "sold" &&
                        <button
                          className="btn btn-primary"
                          onClick={() => {
                            printHandler(item);
                          }}
                        >
                          Печать док.
                        </button>
                      }
                      <button
                        className="btn btn-primary"
                        onClick={() => {
                          getAndSetOrder(item);
                          setShowPrintAssembliList(true);
                        }}
                      >
                        {item.settings?.printedAssemblyList ? <CheckCircle /> : "Сбор.Вед"}
                      </button>
                    </div>
                  </td>
                  <td style={{ width: "4%" }}>
                    <a
                      href={`/sales/orders/${item.id}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {item.number}
                    </a>
                  </td>
                  <td style={{ width: "4%" }}>
                    <div
                      className="deliveryListStatus"
                      style={{ backgroundColor: item.statusColor }}
                    >
                      {item.status}
                    </div>
                  </td>
                  <td style={{ width: "6%", textAlign: "center" }}>
                    {item.delivered ? (
                      <CheckCircle color="green" />
                    ) : (
                      <>
                        {item.statusAlias === "sold" &&
                          <button
                            className="btn btn-primary"
                            onClick={() => deliveryHandler(item.id)}
                          >
                            Отгрузить
                          </button>
                        }
                      </>
                    )}
                  </td>
                  <td style={{ width: "3%" }}>
                    <EditableInput
                      cell={item.settings?.quantityLabels}
                      onChange={(val) => quantityLabelsHandler(item.id, val)}
                    />
                  </td>
                  <td style={{ width: "3%" }}>
                    <EditableInput
                      cell={item.settings?.quantityPallets}
                      onChange={(val) => quantityPalletsHandler(item, +val)}
                    />
                  </td>
                  <td style={{ width: "8%" }}>
                    <Cut>{item.payer}</Cut>
                  </td>
                  <td style={{ width: "7%" }}>
                    <Cut>{item.route}</Cut>
                  </td>
                  <td style={{ width: "8%" }}>
                    <Cut>{item.admin}</Cut>
                  </td>
                  <td style={{ width: "16%" }}>
                    <Cut>{item.comment}</Cut>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div >
      );
    },
    expanded: expanded,
    expandByColumnOnly: true,
  };

  return (
    <>
      {createPortal(
        <>

          <>
            {showPrintUtd && utd &&
              <>
                <PrintUtd utd={utd} />
                <div className="pageBreakBefore" />
                <PrintUtd utd={utd} />
              </>
            }
          </>

          <>
            {showPrintLabels && utd &&
              <PrintLabel order={order} utd={utd} quantityLabels={quantityLabels} />
            }
          </>

          <>
            {showPrintInvoice && utd &&
              <>
                <PrintLabel order={order} utd={utd} quantityLabels={quantityLabels} />
                <div className="pageBreakBefore" />
                <PrintInvoice utd={utd} />
              </>
            }
          </>

          <>
            {showPrintAssembliList && order &&
              <>
                <PrintAssemblyList
                  order={order}
                  setShowPrintAssembliList={setShowPrintAssembliList}
                  setLoading={setLoading}
                  callback={() => getAndSetDeliveryList()}
                />
              </>
            }
          </>

        </>,
        document.body
      )}

      <Card className="mb-2">
        <CardBody className="pt-0 pb-0">
          <Button onClick={expandHandler}>
            {expanded.length === 0 ? "Развернуть" : "Свернуть"}
          </Button>
        </CardBody>
      </Card>
      <BootstrapTable
        keyField="id"
        data={deliveryList}
        columns={tableColumns}
        bootstrap4
        bordered={false}
        hover={true}
        classes="pointer delivery-list"
        expandRow={expandRow}
        rowClasses="mainRow"
      />
      <LabelModal
        id={orderId}
        openModal={openModalLabel}
        setOpenModal={setOpenModalLabel}
        setQuantityLabels={setQuantityLabels}
        quantityLabels={quantityLabels}
        setShowPrintLabels={setShowPrintLabels}
        setShowPrintInvoice={setShowPrintInvoice}
        showPrintLabels={showPrintLabels}
        loading={loading}
      />
    </>
  );
};
