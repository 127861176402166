import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalHeader
} from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import { useHistory } from "react-router-dom";

import { CloseButton } from "components/CloseButton";
import { MySpinner } from "components/MySpinner";
import { getDraftFromDiadoc, getDocFromDiadoc } from "services/apiSupply";
import { priceFormat } from "utils/format";

import './getDocFromDiadoc.scss';


export const GetDocFromDiadocModal = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [docsFromDiadoc, setDocsFromDiadoc] = useState([]);

  useEffect(() => {
    if (!openModal) return;
    setLoading(true);
    getDocFromDiadoc()
      .then((res) => {
        setDocsFromDiadoc(res);
      })
      .catch((e) => console.error(e))
      .finally(() => setLoading(false));
  }, [openModal]);

  const tableColumns = [
    {
      dataField: "customer",
      text: "Поставщик",
      formatter: (cell, row) => row.provider?.name,
      headerStyle: () => {
        return { width: "30%" };
      },
    },
    {
      dataField: "document",
      text: "Документ",
      formatter: (cell, row) => <>№{row.number} от {row.date}</>,
      headerStyle: () => {
        return { width: "30%" };
      },
    },
    {
      dataField: "total",
      text: "Сумма",
      formatter: (cell, row) => <>{priceFormat(row.total)}</>,
      headerClasses: () => "right",
      footerClasses: () => "right",
      classes: (cell, row) => "right bold",
      headerStyle: () => {
        return { width: "20%" };
      },
    },
    {
      dataField: "createdAt",
      text: "Дата создания",
      headerStyle: () => {
        return { width: "20%" };
      },
    }
  ]

  const rowEvents = {
    onClick: (e, row) => {
      if (row.exist) return;
      history.push({
        pathname: "/receipt/supply/new",
        search: `?entityId=${row.entityId}&messageId=${row.messageId}`
      });
    },
  };

  const rowClasses = (row) => {
    if (row.exist) {
      return "exist";
    }
  }

  return (
    <>
      <Button onClick={() => setOpenModal(true)}>Загрузить из Диадок</Button>
      <Modal isOpen={openModal} toggle={() => setOpenModal(false)}>
        <ModalHeader>
          <div className="modal-title-text">Загрузить документ из Диадок</div>
          <CloseButton onClick={() => setOpenModal(false)} />
        </ModalHeader>

        {loading && <MySpinner overlay />}

        <ModalBody className="m-3">

          <BootstrapTable
            wrapperClasses="docDiadoc"
            keyField="id"
            data={docsFromDiadoc}
            columns={tableColumns}
            bootstrap4
            bordered={false}
            hover={true}
            rowEvents={rowEvents}
            classes="pointer"
            rowClasses={rowClasses}
          />


        </ModalBody>
      </Modal>
    </>
  );
};
