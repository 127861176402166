import React, { useEffect, useState } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import ru from "date-fns/locale/ru";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWindowClose } from "@fortawesome/free-solid-svg-icons";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  Input,
  InputGroup,
  Label,
  Row,
} from "reactstrap";
import { Link, useHistory, useLocation } from "react-router-dom";
import Select from "react-select";
import "react-datepicker/dist/react-datepicker.css";

import { getDocFromDiadoc, getSupplies, getSupplyStatuses, getSupplyToExcel, setSupplyStatus } from "services/apiSupply";
import { MySpinner } from "components/MySpinner";
import { Pagination } from "components/Pagination";
import useQuery from "utils/useQuery";
import { SuppliesTable } from "features/Supply/SuppliesTable";
import { dateFormatU, dateTimeFormat } from "utils/format";
import { SelectProvider } from "components/SelectProvider";
import { priceFormat } from "utils/format";
import { Checkbox } from "components/Checkbox";
import { useUser } from "features/Users/UserContext";
import { useOrders } from "features/Orders/OrdersContext";

import "./supplies.scss";
import { GetDocFromDiadocModal } from "features/Supply/GetDocFromDiadocModal";

registerLocale("ru", ru);

export const SuppliesPage = () => {
  const history = useHistory();
  const { pathname } = useLocation();
  const query = useQuery(useLocation);
  const { admins } = useUser();
  const { setStatuses } = useOrders();
  const [supplies, setSupplies] = useState([]);
  const [suppliesCount, setSuppliesCount] = useState(0);
  const [suppliesSumm, setSuppliesSumm] = useState(0);
  const [loading, setLoading] = useState(false);

  const [currentPage, setCurrentPage] = useState(
    query.get("page") ? Number(query.get("page")) : 1
  );
  const getPageSize = localStorage.getItem("pageSize") ? JSON.parse(localStorage.getItem("pageSize")) : {};
  const [pageSize, setPageSize] = useState(getPageSize[pathname] || 10);
  const [provider, setProvider] = useState(
    query.get("provider")
      ? { label: query.get("providerName"), value: query.get("provider") }
      : null
  );
  const [search, setSearch] = useState(query.get("search") || "");
  const [dateStart, setDateStart] = useState(
    query.get("dateStart") ? new Date(query.get("dateStart")) : ""
  );
  const [dateEnd, setDateEnd] = useState(
    query.get("dateEnd") ? new Date(query.get("dateEnd")) : ""
  );
  const [carriedOut, setCarriedOut] = useState(
    query.get("carriedOut") || false
  );

  const [number, setNumber] = useState(query.get("number") || "");
  const [isShowReset, setIsShowReset] = useState(false);
  const [factNum, setFactNum] = useState(query.get("factNum") || "");

  const [admin, setAdmin] = useState(
    localStorage.getItem("filterAdmin")
      ? JSON.parse(localStorage.getItem("filterAdmin"))
      : null
  );

  useEffect(() => {
    setLoading(true);
    getAndSetStatuses();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAndSetSupplies = () => {
    if (loading) return;
    getSupplies({
      paginationCount: pageSize,
      paginationOffset: (currentPage - 1) * pageSize,
      search,
      dateStart: dateFormatU(dateStart),
      dateEnd: dateFormatU(dateEnd),
      number,
      provider: provider?.value,
      admin: admin?.value,
      carriedOut: carriedOut ? 1 : 0,
      factNum,
    })
      .then((res) => {
        setSupplies(res.items);
        setSuppliesCount(res.totalItems);
        setSuppliesSumm(res.totalSumm);
      })
      .catch((e) => console.error(e))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    setLoading(true);
    getAndSetSupplies();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, pageSize]);

  const getAndSetStatuses = () => {
    getSupplyStatuses()
      .then((res) => {
        setStatuses(res);
      })
      .catch((e) => console.error(e));
  };

  useEffect(() => {
    const url = new URLSearchParams();
    if (currentPage !== 1) {
      url.append("page", currentPage);
    }

    if (search) {
      url.append("search", search);
    }

    if (provider?.value) {
      url.append("provider", provider.value);
      url.append("providerName", provider.label);
    }

    if (dateStart) {
      url.append("dateStart", dateFormatU(dateStart));
    }

    if (dateEnd) {
      url.append("dateEnd", dateFormatU(dateEnd));
    }

    if (number) {
      url.append("number", number);
    }

    if (carriedOut) {
      url.append("carriedOut", carriedOut);
    }

    if (factNum) {
      url.append("factNum", factNum);
    }

    if (url) {
      history.push(`${pathname}?${url}`);
    } else {
      history.push(pathname);
    }

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentPage,
    pageSize,
    search,
    provider,
    dateStart,
    dateEnd,
    number,
    carriedOut,
    factNum,
  ]);

  useEffect(() => {
    setLoading(true);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const selectHandler = (val, res) => {
    switch (res.name) {
      case "admin":
        localStorage.setItem("filterAdmin", JSON.stringify(val));
        setAdmin(val);
        break;
      default:
        break;
    }
  };

  const statusHandler = (status, supply) => {
    setLoading(true);
    setSupplyStatus({
      id: supply?.id,
      statusId: status?.id,
    })
      .then(() => {
        getAndSetSupplies();
      })
      .catch((e) => {
        console.error(e);
      });
  };

  const isSearch = () => {
    if (
      search ||
      dateStart ||
      dateEnd ||
      number ||
      provider?.value ||
      admin?.value ||
      carriedOut ||
      factNum
    ) {
      return true;
    }
    return false;
  };

  const searchHandler = () => {
    if (!isSearch()) return;
    if (currentPage !== 1) {
      setCurrentPage(1);
      return;
    }

    setLoading(true);
    getAndSetSupplies();
  };

  const handleKeypress = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      searchHandler();
    }
  };

  useEffect(() => {
    setIsShowReset(isSearch());
    if (!isSearch()) {
      setLoading(true);
      getAndSetSupplies();
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    search,
    dateStart,
    dateEnd,
    number,
    provider,
    admin,
    carriedOut,
    factNum,
  ]);

  const resetFilter = () => {
    setProvider(null);
    setSearch("");
    setDateStart("");
    setDateEnd("");
    setNumber("");
    setAdmin(null);
    setCarriedOut(null);
    setFactNum("");
    localStorage.removeItem("filterAdmin");
  };

  const numberHandler = (e) => {
    setNumber(e.target.value.replace(/[^+\d]/g, ""));
  };

  const downloadSupplyToExcel = () => {
    setLoading(true);
    getSupplyToExcel({
      search,
      dateStart: dateFormatU(dateStart),
      dateEnd: dateFormatU(dateEnd),
      number,
      provider: provider?.value,
      admin: admin?.value,
      carriedOut: carriedOut ? 1 : 0,
      factNum,
    })
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `Поступления_${dateTimeFormat(new Date())}.xlsx`
        );
        document.body.appendChild(link);
        link.click();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Container fluid className="p-0 supplies">
      {loading && <MySpinner overlay />}
      <Card className="mb-0 filter">
        <CardBody>
          <h1 className="h3 mb-3">Поступления</h1>

          <Form className="mb-3">
            <Row>
              <Col>
                <Label>Дата</Label>
                <InputGroup className="daterange">
                  <DatePicker
                    placeholderText="от"
                    selected={dateStart}
                    onChange={setDateStart}
                    selectsStart
                    startDate={dateStart}
                    endDate={dateEnd}
                    dateFormat="dd.MM.yyyy"
                    locale="ru"
                    className="form-control date-start"
                  />
                  <DatePicker
                    placeholderText="до"
                    selected={dateEnd}
                    onChange={setDateEnd}
                    selectsEnd
                    startDate={dateStart}
                    endDate={dateEnd}
                    minDate={dateStart}
                    dateFormat="dd.MM.yyyy"
                    locale="ru"
                    className="form-control date-end"
                  />
                </InputGroup>
              </Col>
              <Col>
                <Label>Поступление №</Label>
                <Input
                  name="number"
                  placeholder="По номеру"
                  value={number}
                  onChange={numberHandler}
                  onKeyUp={handleKeypress}
                />
              </Col>
              <Col>
                <Label>Артикул</Label>
                <Input
                  name="search"
                  placeholder="По артикулу"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  onKeyUp={handleKeypress}
                />
              </Col>
              <Col>
                <Label>Поставщик</Label>
                <SelectProvider provider={provider} setProvider={setProvider} />
              </Col>
            </Row>
            <Row>
              <Col md={3}>
                <Label>Создал</Label>
                <Select
                  name="admin"
                  placeholder="Создал поступление"
                  className="react-select-container"
                  classNamePrefix="react-select"
                  options={admins}
                  value={admin}
                  onChange={selectHandler}
                  isClearable
                />
              </Col>
              <Col md={3}>
                <Label>По номеру сч. фактуры</Label>
                <Input
                  name="factNum"
                  placeholder="По номеру сч. фактуры"
                  value={factNum}
                  onChange={(e) => setFactNum(e.target.value)}
                  onKeyUp={handleKeypress}
                />
              </Col>
              <Col md={2}>
                <Label>Проведено</Label>
                <Checkbox
                  onChange={(e) => setCarriedOut(e.target.checked)}
                  checked={carriedOut}
                >
                  Проведено
                </Checkbox>
              </Col>
              <Col>
                <InputGroup className="with-label">
                  {isShowReset && (
                    <Button color="danger" onClick={() => resetFilter()}>
                      <FontAwesomeIcon icon={faWindowClose} />
                    </Button>
                  )}
                  <Button
                    color="primary"
                    onClick={searchHandler}
                    className="button-wide"
                  >
                    Поиск
                  </Button>
                </InputGroup>
              </Col>
            </Row>
            <Row>
              <Col md={2}>
                Поступлений: {suppliesCount} на сумму{" "}
                {priceFormat(parseFloat(suppliesSumm))}
              </Col>
              <Col md={2}>
                <Button onClick={downloadSupplyToExcel}>Скачать Excel</Button>
              </Col>
              <Col md={2}>
                <GetDocFromDiadocModal />
              </Col>
            </Row>
          </Form>

          <Row>
            <Col md="2">
              <Link
                to={`/receipt/supply/new`}
                color="primary"
                className="button-wide btn btn-primary"
              >
                Добавить поступление
              </Link>
            </Col>
          </Row>
        </CardBody>
      </Card>

      <div>
        <SuppliesTable supplies={supplies} statusHandler={statusHandler} />

        <Card>
          <CardBody>
            <Pagination
              totalCount={suppliesCount}
              currentPage={currentPage}
              pageSize={pageSize}
              setCurrentPage={setCurrentPage}
              onChangePageSize={setPageSize}
            />
          </CardBody>
        </Card>
      </div>
    </Container>
  );
};
