import React, { useEffect, useState } from "react";
import classNames from "classnames";
import Moment from "react-moment";
import Barcode from "react-barcode";

import "./printLabel.scss";
import { cut } from "utils/cut";

export const PrintLabel = ({ provider, number, quantityLabels = 1 }) => {

  useEffect(() => {
    setTimeout(() => {
      window.print();
    }, 200);
  }, []);

  return (
    <>
      {[...Array(parseInt(quantityLabels))].map((_, i) =>
        // <div className={classNames("supplyPrintLabel", { "pageBreakBefore": i % 2 === 0 && i !== 0 })}>
        <div className={classNames("supplyPrintLabel", "pageBreakAfter")}>
          <table>
            <tbody>
              <tr>
                <td className="providerName">
                  {cut(provider.label, 35)}
                </td>
              </tr>
              <tr>
                <td>
                  <Barcode height={40} fontSize={20} value={number} format="CODE128" />
                </td>
              </tr>
              <tr>
                <td className="place">
                  {i + 1} / {quantityLabels}
                </td>
              </tr>
              <tr>
                <td className="date">
                  <Moment format="DD.MM.YYYY" interval={1000} />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
    </>
  );
};