import React from "react";
import Moment from "react-moment";

import "moment/locale/ru";

import "./head.scss";

export const Head = ({ utd }) => {
  return (
    <table className="head">
      <tbody>
        <tr>
          <td className="leftBlock">
            <table className="head-table">
              <tbody>
                <tr>
                  <td>
                    <b>Универсальный
                      передаточный
                      документ
                    </b>
                  </td>
                </tr>
                <tr>
                  <td>
                    Статус: <span>1</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    1 - счет-фактура и передаточный документ (акт)<br />
                    2 - передаточный документ (акт)
                  </td>
                </tr >
              </tbody>
            </table >

          </td >
          <td className="rightBlock">

            <table>
              <tbody>
                <tr>
                  <td>

                    <table className="docInfo">
                      <tbody>
                        <tr>
                          <td>Счет-фактура №</td>
                          <td>{utd.number}</td>
                          <td>от</td>
                          <td>{utd.shippingDate && <Moment format="D MMMM Y">{utd.shippingDate}</Moment>} г.</td>
                          <td>(1)</td>
                        </tr>
                        <tr>
                          <td>Исправление №</td>
                          <td>------</td>
                          <td>от</td>
                          <td>------</td>
                          <td>(1а)</td>
                        </tr>
                      </tbody>
                    </table>

                  </td>
                  <td>

                    <table className="annotation">
                      <tbody>
                        <tr>
                          <td>
                            Приложение № 1 к постановлению Правительства Российской Федерации от 26 декабря 2011 г. № 1137
                          </td>
                        </tr>
                        <tr>
                          <td>
                            (в редакции постановления Правительства Российской Федерации от 16 августа 2024 г. № 1096))
                          </td>
                        </tr>
                      </tbody>
                    </table>

                  </td>
                </tr>
              </tbody>
            </table>

            <table className="orgInfo">
              <tbody>
                <tr>
                  <td><b>Продавец</b></td>
                  <td>
                    {utd.seller?.name}
                  </td>
                  <td>(2)</td>
                </tr>
                <tr>
                  <td>Адрес</td>
                  <td>
                    {utd.seller?.address}
                  </td>
                  <td>(2а)</td>
                </tr>
                <tr>
                  <td>ИНН/КПП продавца</td>
                  <td>{utd.seller?.inn}/{utd.seller?.kpp}</td>
                  <td>(2б)</td>
                </tr>
                <tr>
                  <td>Грузоотправитель и его адрес</td>
                  <td>он же</td>
                  <td>(3)</td>
                </tr>
                <tr>
                  <td>Грузополучатель и его адрес</td>
                  <td>
                    {utd.consigneeName || utd.buyerOrgName}, {utd.consigneeAddress || utd.buyerAddress}
                  </td>
                  <td>(4)</td>
                </tr>
                <tr>
                  <td>К платежно-расчетному документу</td>
                  <td>--</td>
                  <td>(5)</td>
                </tr>
                <tr>
                  <td>Документ об отгрузке</td>
                  <td>Универсальный передаточный документ № {utd.number} от <Moment format="DD.MM.YYYY">{utd.shippingDate}</Moment> г.</td>
                  <td>(5а)</td>
                </tr>
                <tr>
                  <td><b>Покупатель</b></td>
                  <td>{utd.buyerOrgName}</td>
                  <td>(6)</td>
                </tr>
                <tr>
                  <td>Адрес</td>
                  <td>
                    {utd.buyerAddress}
                  </td>
                  <td>
                    (6а)
                  </td>
                </tr>
                <tr>
                  <td>ИНН/КПП покупателя</td>
                  <td>{utd.buyerInn}/{utd.buyerKpp}</td>
                  <td>(6б)</td>
                </tr>
                <tr>
                  <td>Валюта: наименование, код</td>
                  <td>Российский рубль, 643</td>
                  <td>(7)</td>
                </tr>
                <tr>
                  <td colSpan="2">
                    Идентификатор государственного контракта, договора (соглашения) (при наличии):
                  </td>
                  <td>(8)</td>
                </tr>
              </tbody>
            </table>

          </td>
        </tr>
      </tbody>
    </table >
  )
}