import React, { useEffect, useState } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import ru from "date-fns/locale/ru";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  Input,
  InputGroup,
  Label,
  Row,
} from "reactstrap";
import { Link, useHistory, useLocation } from "react-router-dom";
import Select from "react-select";
import { faWindowClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  getReturnsToProvider,
  getReturnsToProviderStatuses,
  getReturnsToProviderToExcel,
  updateReturnToProvider,
} from "services/apiSupply";
import useQuery from "utils/useQuery";
import { Pagination } from "components/Pagination";
import { ReturnsToProviderTable } from "features/ReturnsToProvider/ReturnsToProviderTable";
import { SelectProvider } from "components/SelectProvider";
import { Checkbox } from "components/Checkbox";
import { dateFormatU, dateTimeFormat, priceFormat } from "utils/format";
import { MySpinner } from "components/MySpinner";
import { useUser } from "features/Users/UserContext";
import { colorStyles, selectedStatuses } from "utils/select2";

import "./returnsToProvider.scss";

registerLocale("ru", ru);

export const ReturnsToProviderPage = () => {
  const history = useHistory();
  const { pathname } = useLocation();
  const query = useQuery(useLocation);
  const { admins } = useUser();
  const [returnsToProvider, setReturnsToProvider] = useState([]);
  const [returnsToProviderCount, setReturnsToProviderCount] = useState(0);
  const [returnsToProviderSumm, setReturnsToProviderSumm] = useState(0);
  const [loading, setLoading] = useState(false);
  const [statuses, setStatuses] = useState([]);
  const [dateStart, setDateStart] = useState(
    query.get("dateStart")
      ? new Date(query.get("dateStart"))
      : new Date("2025-01-01")
  );
  const [dateEnd, setDateEnd] = useState(
    query.get("dateEnd") ? new Date(query.get("dateEnd")) : ""
  );
  const [admin, setAdmin] = useState(
    query.get("admin")
      ? { label: query.get("adminName"), value: query.get("admin") }
      : null
  );
  const [provider, setProvider] = useState(
    query.get("provider")
      ? { label: query.get("providerName"), value: query.get("provider") }
      : null
  );
  const [filterStatus, setFilterStatus] = useState(
    localStorage.getItem("filterStatusRtp")
      ? JSON.parse(localStorage.getItem("filterStatusRtp"))
      : []
  );
  const [carriedOut, setCarriedOut] = useState(
    query.get("carriedOut") || false
  );
  const [search, setSearch] = useState("");
  const [number, setNumber] = useState("");
  const [isShowReset, setIsShowReset] = useState(false);

  const [currentPage, setCurrentPage] = useState(
    query.get("page") ? Number(query.get("page")) : 1
  );
  const getPageSize = localStorage.getItem("pageSize") ? JSON.parse(localStorage.getItem("pageSize")) : {};
  const [pageSize, setPageSize] = useState(getPageSize[pathname] || 10);

  const getAndSetReturnsToProvider = () => {
    getReturnsToProvider({
      paginationCount: pageSize,
      paginationOffset: (currentPage - 1) * pageSize,
      dateStart: dateFormatU(dateStart),
      dateEnd: dateFormatU(dateEnd),
      search,
      number,
      admin: admin?.value,
      provider: provider?.value,
      carriedOut: carriedOut ? 1 : 0,
      status: filterStatus.map((val) => val.value)
    })
      .then((res) => {
        setReturnsToProvider(res.items);
        setReturnsToProviderCount(res.totalItems);
        setReturnsToProviderSumm(res.totalSumm);
      })
      .catch((e) => console.error(e))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    setLoading(true);
    getAndSetStatuses();
    getAndSetReturnsToProvider();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, pageSize]);

  useEffect(() => {
    const url = new URLSearchParams();
    if (currentPage !== 1) {
      url.append("page", currentPage);
    }

    if (search) {
      url.append("search", search);
    }

    if (admin?.value) {
      url.append("admin", admin.value);
      url.append("adminName", admin.label);
    }

    if (provider?.value) {
      url.append("provider", provider.value);
      url.append("providerName", provider.label);
    }

    if (dateStart) {
      url.append("dateStart", dateFormatU(dateStart));
    }

    if (dateEnd) {
      url.append("dateEnd", dateFormatU(dateEnd));
    }

    if (number) {
      url.append("number", number);
    }

    if (carriedOut) {
      url.append("carriedOut", carriedOut);
    }

    if (url) {
      history.push(`${pathname}?${url}`);
    } else {
      history.push(pathname);
    }

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentPage,
    pageSize,
    search,
    number,
    admin?.value,
    provider?.value,
    carriedOut,
    dateStart,
    dateEnd,
  ]);

  const rowEvents = {
    onClick: (e, row) => {
      history.push(`/receipt/returnsToProvider/${row.id}`);
    },
  };

  const selectHandler = (val, res) => {
    switch (res.name) {
      case "admin":
        setAdmin(val);
        break;
      case "status":
        localStorage.setItem("filterStatusRtp", JSON.stringify(val));
        setFilterStatus(val);
        break;
      default:
        break;
    }
  };

  const isSearch = () => {
    if (
      search ||
      number ||
      admin?.value ||
      provider?.value ||
      carriedOut ||
      dateStart ||
      dateEnd ||
      filterStatus
    ) {
      return true;
    }
    return false;
  };

  const searchHandler = () => {
    if (!isSearch()) return;
    if (currentPage !== 1) {
      setCurrentPage(1);
      return;
    }
    setLoading(true);
    getAndSetReturnsToProvider();
  };

  const handleKeypress = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      searchHandler();
    }
  };

  useEffect(() => {
    setIsShowReset(isSearch());
    if (!isSearch()) {
      setLoading(true);
      getAndSetReturnsToProvider();
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    search,
    number,
    admin?.value,
    provider?.value,
    carriedOut,
    dateStart,
    dateEnd,
    filterStatus
  ]);

  const resetFilter = () => {
    setSearch("");
    setNumber("");
    setAdmin(null);
    setProvider(null);
    setCarriedOut(false);
    setDateStart("");
    setDateEnd("");
  };

  const numberHandler = (e) => {
    setNumber(e.target.value.replace(/[^+\d]/g, ""));
  };

  const downloadReturnsToProviderToExcel = () => {
    setLoading(true);
    getReturnsToProviderToExcel({
      dateStart: dateFormatU(dateStart),
      dateEnd: dateFormatU(dateEnd),
      search,
      number,
      admin: admin?.value,
      provider: provider?.value,
      carriedOut: carriedOut ? 1 : 0,
    })
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `Возвраты_поставщику_${dateTimeFormat(new Date())}.xlsx`
        );
        document.body.appendChild(link);
        link.click();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getAndSetStatuses = () => {
    getReturnsToProviderStatuses()
      .then((res) => {
        setStatuses(res);
      })
      .catch((e) => console.error(e));
  };

  const statusHandler = (returnToProvider, status) => {

    console.log(status);
    console.log(returnToProvider);
    setLoading(true);
    updateReturnToProvider({
      id: returnToProvider,
      status: status?.id
    })
      .then(() => {
        getAndSetReturnsToProvider();
      })
      .catch((e) => {
        console.error(e);
      });
  };

  const statusesList = () => {
    return statuses.map((val) => {
      return {
        label: val.name,
        value: val.id,
        color: val.color,
      };
    });
  };

  return (
    <Container fluid className="p-0 returns-to-provider">
      {loading && <MySpinner overlay />}
      <Card className="mb-0 filter">
        <CardBody>
          <h1 className="h3 mb-3">Возвраты поставщику</h1>

          <Form className="mb-3">
            <Row>
              <Col md={3}>
                <Label>Дата</Label>
                <InputGroup className="daterange">
                  <DatePicker
                    placeholderText="от"
                    selected={dateStart}
                    onChange={setDateStart}
                    selectsStart
                    startDate={dateStart}
                    endDate={dateEnd}
                    dateFormat="dd.MM.yyyy"
                    locale="ru"
                    className="form-control date-start"
                  />
                  <DatePicker
                    placeholderText="до"
                    selected={dateEnd}
                    onChange={setDateEnd}
                    selectsEnd
                    startDate={dateStart}
                    endDate={dateEnd}
                    minDate={dateStart}
                    dateFormat="dd.MM.yyyy"
                    locale="ru"
                    className="form-control date-end"
                  />
                </InputGroup>
              </Col>

              <Col md={3}>
                <Label>Номер</Label>
                <Input
                  name="number"
                  placeholder="По номеру"
                  value={number}
                  onChange={numberHandler}
                  onKeyPress={handleKeypress}
                />
              </Col>
              <Col md={3}>
                <Label>Артикул</Label>
                <Input
                  name="search"
                  placeholder="По артикулу"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  onKeyPress={handleKeypress}
                />
              </Col>
              <Col md={3}>
                <Label>Создал</Label>
                <Select
                  name="admin"
                  placeholder="Сотрудник"
                  className="react-select-container"
                  classNamePrefix="react-select"
                  options={admins}
                  value={admin}
                  onChange={selectHandler}
                  isClearable
                />
              </Col>
            </Row>

            <Row>
              <Col md={4}>
                <Label>Статус</Label>
                <Select
                  isMulti
                  name="status"
                  placeholder="Статус"
                  className="react-select-container"
                  classNamePrefix="react-select"
                  options={statusesList()}
                  value={selectedStatuses(statuses, filterStatus)}
                  onChange={selectHandler}
                  styles={colorStyles}
                />
              </Col>
              <Col md={3}>
                <Label>Поставщик</Label>
                <SelectProvider provider={provider} setProvider={setProvider} />
              </Col>
              <Col md={2}>
                <Label>Проведено</Label>
                <Checkbox
                  onChange={(e) => setCarriedOut(e.target.checked)}
                  checked={carriedOut}
                >
                  Проведено
                </Checkbox>
              </Col>
              <Col>
                <InputGroup className="with-label">
                  {isShowReset && (
                    <Button color="danger" onClick={() => resetFilter()}>
                      <FontAwesomeIcon icon={faWindowClose} />
                    </Button>
                  )}
                  <Button
                    color="primary"
                    onClick={searchHandler}
                    className="button-wide"
                  >
                    Поиск
                  </Button>
                </InputGroup>
              </Col>
            </Row>
          </Form>
          <Row>
            <Col md="2">
              <Link
                to={`/receipt/returnsToProvider/new`}
                color="primary"
                className="button-wide btn btn-primary"
              >
                Добавить
              </Link>
            </Col>

            <Col md={2}>
              Возвратов: {returnsToProviderCount} на сумму{" "}
              {priceFormat(parseFloat(returnsToProviderSumm))}
            </Col>
            <Col md={2}>
              <Button onClick={downloadReturnsToProviderToExcel}>
                Скачать Excel
              </Button>
            </Col>
          </Row>
        </CardBody>
      </Card>

      <div>
        <ReturnsToProviderTable
          returnsToProvider={returnsToProvider}
          rowEvents={rowEvents}
          statusHandler={statusHandler}
          statuses={statuses}
        />

        <Card>
          <CardBody>
            <Pagination
              totalCount={returnsToProviderCount}
              currentPage={currentPage}
              pageSize={pageSize}
              setCurrentPage={setCurrentPage}
              onChangePageSize={setPageSize}
            />
          </CardBody>
        </Card>
      </div>
    </Container>
  );
};
