import React, { useEffect, useState } from "react";
import classNames from "classnames";

import { dateFormat, timeFormat } from "utils/format";
import { ROUTES } from "features/Users/Addresses";
import { setPrintedAssemblyList } from "services/api";

import "./printAssemblyList.scss";


export const PrintAssemblyList = ({ order, setShowPrintAssembliList, setLoading, callback }) => {

  useEffect(() => {
    setTimeout(() => {
      window.print();
    }, 200);
    setTimeout(() => {
      setShowPrintAssembliList(false);
    }, 200);

    window.addEventListener('afterprint', function () {

      setLoading(true);
      setPrintedAssemblyList({
        id: order.id,
      })
        .then((res) => {
          if (callback) {
            callback(res);
          }
        })
        .finally(() => {
          setLoading(false);
        });

    });
  }, []);

  const providerRender = (product) => {

    const provAdd = [];
    if (product.providersStock.length > 0) {
      for (const prov of product.providersStock) {
        if (prov.id !== product.provider.id) {
          provAdd.push(prov.name);
        }
      }
    }

    return product.provider.name + (provAdd.length > 0 ? ` (${provAdd.join(', ')})` : "");
  }

  const stockRender = (product) => {

    if (product.inStockAll && product.inStockAll !== product.inStock) {
      return product.inStock + ` (${product.inStockAll})`
    }

    return product.inStock;
  }

  const sorted = (products) => {
    return products.sort(function (a, b) {
      if (a.provider.name > b.provider.name) {
        return 1;
      }
      if (a.provider.name < b.provider.name) {
        return -1;
      }
      return 0;
    });
  }

  return (
    <>
      <style>
        {`@media print {
            @page {
              size: A4 landscape;
              margin: 0;
				    }}
          }`
        }
      </style>

      <div className={classNames("printAssemblyList")}>
        <div className="infoBlock">
          <div className="number">
            Заказ № {order.number} от {dateFormat(order.date)}{' '}
            <span className="time">{timeFormat(order.date)}</span>
          </div>

          <div className="headItem">
            <div className="headItem__title">Клиент</div>
            {order.customer.name}
          </div>

          <div className="headItem">
            <div className="headItem__title">Город доставки</div>
            {order.address?.city}
          </div>
          <div className="headItem">
            <div className="headItem__title">Адрес доставки</div>
            {order.address?.address}
          </div>

          {order.delivery?.id === 3 &&
            <div className="headItem transport">
              <div className="headItem__title">Транспортная компания</div>
              {order.address?.transportCompany}
            </div>
          }

          <div className="headItem">
            <div className="headItem__title">Комментарий к заказу</div>
            {order.comment}
          </div>

          <div className="headItem route">
            <div className="headItem__title">Маршрут доставки</div>
            {ROUTES.find(v => v.value === order.route)?.shortName}
          </div>

          <div className="headItem">
            <div className="headItem__title">Менеджер</div>
            {order.admin.name}
          </div>
        </div>


        <table>
          <thead>
            <tr>
              <th>п/н</th>
              <th>Поставщик</th>
              <th>Наименование</th>
              <th>Бренд</th>
              <th>Артикул</th>
              <th>Ячейка</th>
              <th>Кол-во</th>
              <th>Поступление</th>
              <th>Комментарий</th>
            </tr>
          </thead>
          <tbody>
            {sorted(order.products).map((product, i) =>
              <tr
                className={
                  classNames(
                    { "red-bg": product.reserveProduct !== product.count && product.inStock < product.count }
                  )
                }
                key={product.id}
              >
                <td>{i + 1}</td>
                <td>
                  {providerRender(product)}
                </td>
                <td>{product.nomenclature.name}</td>
                <td>{product.nomenclature.brand}</td>
                <td>{product.nomenclature.article}</td>
                <td className="nowrap">{product.nomenclature.cell}</td>
                <td>{product.count}</td>
                <td dangerouslySetInnerHTML={{
                  __html: product.supplyStock.join(', ')
                }} />
                <td></td>
              </tr>
            )}
          </tbody>
        </table>
        <br />
        <br />
        <div className="bottom">
          <p>Всего мест: {order.settings.quantityLabels}</p>
          <p>Паллет: {order.settings.quantityPallets}</p>
          <p>Выполнил: ____________________________________________</p>
        </div>
      </div >
    </>
  );
};